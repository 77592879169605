import CardsIcon from "@/assets/images/cards.webp";
import FawryIcon from "@/assets/images/fawry.svg?react";
import MasterCardIcon from "@/assets/images/mastercard.svg?react";
import MeezaIcon from "@/assets/images/meeza.svg?react";
import VisaIcon from "@/assets/images/visa.svg?react";
import { SUBSCRIPTION_AMOUNT } from "@/utils/Constants";
import { FormEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import MainForm from "../MainForm/MainForm";

import classes from "./PaymentOptions.module.css";

export default function PaymentOptions({
  submitHandler,
  errorMsg,
  isPending,
  isPaymobLoading,
}: {
  submitHandler: FormEventHandler<HTMLFormElement> | undefined;
  errorMsg: string;
  isPending: boolean;
  isPaymobLoading: boolean;
}) {
  const [paymentOption, setPaymentOption] = useState<"fawry" | "paymob">("fawry");

  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <MainForm
      title={t("choose_payment_label")}
      onSubmit={submitHandler}
      onChange={e => {
        if (
          e.target &&
          "value" in e.target &&
          typeof e.target.value === "string" &&
          (e.target.value === "fawry" || e.target.value === "paymob")
        ) {
          setPaymentOption(e.target.value);
        }
      }}
    >
      <ul className={classes.optionsMenu}>
        <li>
          <input type='radio' id='fawry' name='payment' value='fawry' defaultChecked />
          <label htmlFor='fawry'>
            <FawryIcon />
            {t("fawry_label")}
          </label>
        </li>
        <li>
          <input type='radio' id='cards' name='payment' value='paymob' />
          <label htmlFor='cards'>
            <p>
              <img src={CardsIcon} />
              {t("payment_cards_label")}
            </p>
            <div>
              <MasterCardIcon />
              <VisaIcon />
              <MeezaIcon />
            </div>
            <p className={classes.errorMsg}>{errorMsg}</p>
          </label>
        </li>
      </ul>
      <div className={classes.textContainer}>
        <p>
          {t(
            paymentOption === "paymob" ? "pay_with_payment_cards_message" : "fawry_payment_message"
          )}
        </p>
        <p>
          {t("agreement_message")}{" "}
          <Link to={`/${language}/privacy/`}>{t("privacy_policy_label")}</Link> {t("and_label")}{" "}
          <Link to={`/${language}/terms_conditions/`}>{t("terms_conditions_label")}</Link>.
        </p>
      </div>
      <div className={classes.ctaContainer}>
        <p>
          {t("pay_only_label")}{" "}
          {language === "en" ? (
            <strong>
              {t("egp_currency_label")} {SUBSCRIPTION_AMOUNT}
            </strong>
          ) : (
            <strong>
              {SUBSCRIPTION_AMOUNT} {t("egp_currency_label")}
            </strong>
          )}{" "}
          {/* <s>
            {t("egp_currency_label")} {DISCOUNTED_AMOUNT}
          </s> */}
        </p>
        <Button type='submit' variant='green' disabled={isPending || isPaymobLoading}>
          {t("continue_label")}
        </Button>
      </div>
    </MainForm>
  );
}
