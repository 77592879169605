import LinkBtn from "@/Components/LinkBtn/LinkBtn";
import Modal from "@/Components/Modal";
import PaymentOptions from "@/Components/PaymentOptions/PaymentOptions";
import WalletIcon from "@/assets/images/success.svg?react";
import useUserActionsStore from "@/store/UserActionsStore";
import useUserStore from "@/store/UserStore";
import { Language, PaymobResponseBody } from "@/types";
import { PAYMENT_CLIENT_ID, SUBSCRIPTION_AMOUNT } from "@/utils/Constants";
import { payment } from "@/utils/DataFetchers";
import { formatDateStr } from "@/utils/Utils";
import { useMutation } from "@tanstack/react-query";
import { ElementRef, FormEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import classes from "./PaymentPage.module.css";

type Option = "paymob" | "fawry";

export default function PaymentPage() {
  const [errorMsg, setErrorMsg] = useState("");

  const [isPaymobLoading, setIsPaymobLoading] = useState(false);

  const { pathname } = useLocation();
  const [searchParams, _] = useSearchParams();
  const navigate = useNavigate();

  const successfulPaymentModalRef = useRef<ElementRef<"dialog">>(null);

  const { redirectPath } = useUserActionsStore(state => state);
  const { user } = useUserStore(state => state);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { mutate, isPending } = useMutation({
    mutationFn: payment,
    onSuccess: (response: PaymobResponseBody) => {
      setErrorMsg("");

      const { paymentCode } = response;
      window.open(
        `https://beta-payments.codebites.com/paymobcreditcard/${language}/codebites/${paymentCode}/`,
        "_self"
      );
    },
    onError: error => {
      setErrorMsg(error.message);
    },
    onSettled: () => {
      setIsPaymobLoading(false);
    },
  });

  useEffect(() => {
    if (searchParams.has("success") && searchParams.get("success") === "true") {
      successfulPaymentModalRef.current?.showModal();
    }
  }, [searchParams]);

  const optionsSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const option = formData.get("payment") as Option;
    if (option === "fawry") {
      // STATE TO CHECK IF THE USER GOT TO THE MOBILE PAGE WITH THE URL DIRECTLY
      navigate("fawry_mobile_number", { state: "fawryOption" });
    } else {
      mutate({
        type: option,
        amount: SUBSCRIPTION_AMOUNT,
        clientId: PAYMENT_CLIENT_ID,
        language: language as Language,
        successReturnUrl: `${window.origin}${pathname}?success=true`,
        failureReturnUrl: `${window.origin}${pathname}?success=false`,
      });
    }
  };

  return (
    <section className='container-50 form-wrapper'>
      <Modal
        variant='headless'
        modalRef={successfulPaymentModalRef}
        className={classes.successfulPopUp}
        outsideClickToClose={false}
        pressEscToClose={false}
      >
        <div className={classes.topContent}>
          <LinkBtn
            variant=''
            to={redirectPath && !redirectPath.includes("payment") ? redirectPath : `/${language}/`}
            className={`close-btn sm ${classes.closeBtn}`}
            replace={true}
          ></LinkBtn>
          <WalletIcon />
          <h2>{t("subscription_successfully_pop_up_title")}</h2>
          <p>{t("subscription_successfully_pop_up_message")}</p>
          {user?.subscriptionDetails?.end_date && (
            <p>
              {t("subscribe_due_date_label", {
                subscription_due_date: formatDateStr(
                  user.subscriptionDetails.end_date,
                  language as Language
                ),
              })}
            </p>
          )}
        </div>
        <div className={classes.ctaContainer}>
          <LinkBtn
            variant='signUp'
            to={redirectPath && !redirectPath.includes("payment") ? redirectPath : `/${language}/`}
            replace={true}
          >
            {t("finish_label")}
          </LinkBtn>
        </div>
      </Modal>
      <PaymentOptions
        submitHandler={optionsSubmitHandler}
        errorMsg={errorMsg}
        isPending={isPending}
        isPaymobLoading={isPaymobLoading}
        key='paymentOptions'
      />
    </section>
  );
}
