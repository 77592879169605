import PrivacyPolicyIcon from "@/assets/images/privacy-policy.svg?react";
import { Link } from "react-router-dom";

import classes from "./../PrivacyPageContent.module.css";

export default function PrivacyPageEnContent() {
  return (
    <section className={`container-80 ${classes.privacySection}`}>
      <h1 className={classes.privacyHeader}>
        <PrivacyPolicyIcon />
        Privacy Policy
      </h1>
      <div className={classes.privacyContainer}>
        <h2>What Does This Privacy Policy Cover?</h2>
        <p>
          CodeBites is a product provided by Nagwa Limited (hereinafter Nagwa). This Privacy Policy
          explains how CodeBites, on behalf of Nagwa collect and use personal information in
          relation to the products and services that are linked to this Policy. These products and
          services shall be referred to as our “Services” for the rest of this Policy. We will use
          CodeBites to refer to this Nagwa product for the rest of this policy.
        </p>
        <p>
          Our Services may be purchased by individuals (collectively referred to as “Individuals” in
          this Policy).
        </p>
        <p>
          In this Policy, a “User” is defined as any person making use of CodeBites Services. In
          each case, we may have to collect “User Data” (here defined as any personal information or
          data pertaining to any User that is collected by, or on behalf of, Nagwa) in order to
          provide our Services.
        </p>
        <p>
          This Policy covers how CodeBites handles User Data as a data controller when providing
          Services to Individuals.
        </p>
        <h2>CodeBites Commitment</h2>
        <p>
          We are committed to upholding the rights of users as defined under the European Union
          General Data Protection Regulation (GDPR) and by following these Privacy Principles:
        </p>
        <ul>
          <li>Creating a safe and secure digital learning environment for you,</li>
          <li>
            Not sharing or selling your personal information to third parties that are not directly
            involved in delivering Nagwa Services (unless we are required to by law),
          </li>
          <li>Providing you with access to and control over the information you give us,</li>
          <li>Not showing any external advertisements on CodeBites Services.</li>
        </ul>
        <p>
          We consider all User Data that we collect to be strictly confidential. In general, we do
          not use such data for any purpose other than improving and providing our Services to the
          User. Our collection, use and sharing of User Data is governed by the Individual that has
          purchased our Services.
        </p>
        <h2>What User Data Does CodeBites Collect and Store?</h2>
        <p>
          In order to provide our Services effectively, there are a number of pieces of information
          (User Data) that CodeBites needs to collect and store.
        </p>
        <p>
          By subscribing to our Services, you consent to CodeBites storing and processing the
          following information about you:
        </p>
        <ul>
          <li>First and last name,</li>
          <li>Email address,</li>
          <li>Country of residence,</li>
          <li>
            Usage of CodeBites Services, such as the pages you visit and the actions you take.
          </li>
        </ul>
        <p>
          For more information on what data will be collected by specific CodeBites Services, please
          contact <Link to='mailto:nagwa@nagwa.com'>nagwa@nagwa.com</Link>.
        </p>
        <h2>How Do CodeBites Services Collect User Data?</h2>
        <p>We collect the information that forms your User Data when:</p>
        <ul>
          <li>You share your information with us by registering with us or using our Services,</li>
          <li>You contact us via either electronic or non-electronic methods,</li>
          <li>
            You open emails from us or interact with our website. This information is collected
            through cookies. Please read our cookie policy for more information.
          </li>
        </ul>
        <p>
          We use Google Analytics to collect, monitor, and analyze information. Google has its own
          privacy policy, which can be found at{" "}
          <Link to='https://policies.google.com/privacy'>https://policies.google.com/privacy.</Link>
        </p>
        <p>
          We use Adjust, a mobile app analytics and marketing software. Their privacy policy can be
          found at{" "}
          <Link to='https://www.adjust.com/terms/privacy-policy/'>
            https://www.adjust.com/terms/privacy-policy/.
          </Link>
        </p>
        <p>
          We also use META Services (such as advertising and social login). Their privacy policy can
          be found at{" "}
          <Link to='https://web.facebook.com/about/privacy/'>
            https://web.facebook.com/about/privacy/.
          </Link>
        </p>
        <h2>Who Will CodeBites Share My Data With?</h2>
        <p>
          We do not sell any User Data. We also do not share any User Data with any third parties
          not directly involved in the delivery of CodeBites Services, unless required to by law.
        </p>
        <p>
          CodeBites may release User Data if it has a good faith belief that access, use,
          preservation, or disclosure of such information is reasonably necessary to:
        </p>
        <ul>
          <li>
            Satisfy any applicable law, regulation, legal process, or enforceable governmental
            request,
          </li>
          <li>
            Enforce applicable Terms of Service, including investigation of potential violations
            thereof,
          </li>
          <li>Detect, prevent, or otherwise address fraud, security, or technical issues,</li>
          <li>
            Protect against imminent harm to the rights, property, or safety of Nagwa and CodeBites,
            its users, or the public as required or permitted by law.
          </li>
        </ul>
        <p>
          We may also have to share User Data if Nagwa becomes involved in a merger, acquisition, or
          any form of sale of some or all of its assets. The legal basis for such a transfer would
          be our legitimate interests. You would be given notice of the proposed transfer at the
          relevant time and the option to have your account deleted. In such an event, we will
          provide notice before personal information is transferred and becomes subject to a
          different privacy policy.
        </p>
        <p>
          We use anonymized aggregated data to analyze how our Users interact with our Services in
          order to improve them and come up with new Services. When we do this, we ensure that all
          direct identifiers to any person are removed, meaning the data is no longer considered
          personalized for the purposes of data privacy regulations.
        </p>
        <h2>What Laws and Regulations Does CodeBites Follow When Handling My Data?</h2>
        <p>
          When you purchase access to CodeBites Services or when we contact you for marketing
          purposes, we act as a data controller. Following the European General Data Protection
          Regulation, we are required to inform you of the legal basis for handling your User Data.
          We may use User Data:
        </p>
        <ul>
          <li>To enter into a contract with you (such as when you purchase CodeBites Services),</li>
          <li>
            To comply with our legal obligations (such as if we are obligated to report, share, or
            archive personal information in a specific way),
          </li>
          <li>
            For a range of business purposes that are in our legitimate interests as a commercial
            provider of educational Services,
          </li>
          <li>
            With your consent (such as when we specifically ask you to consent to the use of your
            User Data in a particular way).
          </li>
        </ul>
        <h2>Will CodeBites Send Me Marketing Communications, and How Can I Opt Out?</h2>
        <p>
          We may use the contact details provided by Users of our Services to inform Users about
          other Services on offer from CodeBites unless the User chooses to opt out.
        </p>
        <p>
          Marketing communications will not be sent to any User that has opted out of receiving
          them. Users may opt out of receiving marketing communications at any time. All marketing
          communications sent by CodeBites will contain an “Unsubscribe” link at the end of the
          communication.
        </p>
        <h2>How Can I Help Keep My Data Safe?</h2>
        <p>
          Securing your User Data is very important to us. We take appropriate security measures to
          protect against unauthorized access and to verify your identity before granting your
          account access or making corrections to your information.
        </p>
        <p>
          We advise you to create and maintain a strong password to help ensure the security of your
          account and to never share it with others. We also urge you to only use our Services via
          secure networks and to maintain up-to-date internet security and virus protection on the
          devices from which you access our Services. Please change your password immediately if you
          suspect there has been a data breach or if you have security concerns, and contact us
          using <Link to='mailto:nagwa@nagwa.com'>nagwa@nagwa.com</Link>.
        </p>
        <p>
          If User Data is collected improperly or misused, this is considered a violation of this
          Privacy Policy. Please report it to{" "}
          <Link to='mailto:nagwa@nagwa.com'>nagwa@nagwa.com</Link>.
        </p>
        <p>
          If we become aware that any Individual is in violation of this Privacy Policy or our Terms
          and Conditions, we may immediately terminate, without notice, the Individual or
          Organization’s access to our Services.
        </p>
        <h2>How Long Will CodeBites Keep My Data?</h2>
        <p>
          We will keep your User Data for as long as you are a CodeBites customer and for any
          duration after this time where your data is required for processing in order to fulfill
          our Services, unless the law requires or permits longer.
        </p>
        <h2>Will My Data Be Sent to Another Country?</h2>
        <p>
          We provide our Services internationally. In accordance with GDPR requirements, all service
          providers are audited to check that they comply with GDPR requirements and substantially
          follow CodeBites Privacy Policy.
        </p>
        <p>
          To deliver the Service, we use a number of third-party data processors that are
          contractually committed to delivering the Privacy Policy described in this document.
        </p>
        <p>
          Your information, including personalized User Data, may be transferred to (and maintained
          on) computers located outside of your state, province, eg, or other governmental
          jurisdiction where the data protection laws may differ from those of your jurisdiction.
          This includes areas outside of the European Union. To ensure GDPR compliance, CodeBites
          has standard contractual clauses in place where necessary. For example, companies in the
          US are registered with Privacy Shield.
        </p>
        <p>
          Your consent to this Privacy Policy, followed by your submission of such information,
          represents your agreement to that transfer.
        </p>
        <p>
          In the event that a dispute arises with regard to the international transfer of data, you
          agree that the courts of England and Wales shall have exclusive jurisdiction over the
          matter.
        </p>
        <h2>What If I Am Under 18 Years of Age?</h2>
        <p>
          We provide Services that may be aimed at Users under the age of 18. We take the utmost
          care to only request the information necessary to provide our Services from these Users.
        </p>
        <p>
          If you are under the age of 18 and have any questions regarding your User Data and how it
          is used by our Services, we recommend you contact your parent or legal guardian, or the
          Organization through which your access to our Services has been set up.
        </p>
        <h2>What Rights Do I Have Regarding My User Data?</h2>
        <p>
          You may have rights in certain regions, such as the European Economic Area, in relation to
          your User Data. This includes the right to update and correct your personal information,
          obtain a copy of your data upon request, and erasure of your data if it is no longer
          essential for us to retain it in order to fulfill the purposes for which it was collected.
          You may also request to restrict the processing of your data, in which case we may no
          longer be able to offer you our Services.
        </p>
        <p>
          If you directly purchased access to CodeBites Services as an Individual customer, then you
          can contact us with your request using the contact details given at the end of this
          Privacy Policy. We will consider and act upon any request in accordance with any
          applicable data protection laws.
        </p>
        <p>
          If your access to CodeBites Services was purchased by yourself, a parent, or a guardian,
          and you are unhappy with our privacy practices, you may have the right to complain to the
          Information Commissioner (if you are based in the UK), or to your local data protection
          supervisory authority.
        </p>
        <h2>Will We Make Changes to This Privacy Policy?</h2>
        <p>
          From time to time we may make changes to this Privacy Policy. We will always clearly
          display the date on which the latest update was made.
        </p>
        <h2>How Can I Contact CodeBites?</h2>
        <p>If you have any queries about our Privacy Policy, please contact us at:</p>
        <address>
          <p>Nagwa Limited</p>
          <p>York House</p>
          <p>41 Sheet Street</p>
          <p>Windsor</p>
          <p>SL4 1DD</p>
          <p>
            T: <Link to='tel:+4401753832522'>+44 (0)1753 832 522</Link>
          </p>
          <p>
            Email: <Link to='mailto:nagwa@nagwa.com'>nagwa@nagwa.com</Link>
          </p>
        </address>
      </div>
    </section>
  );
}
