import type { Language } from "@/types";

const BASE_URL = "https://beta-api.codebites.com" as const;

const API_VERSION = "v1" as const;

export const ROUTES = {
  courses: {
    url: (lang: Language = "en") => `${BASE_URL}/${API_VERSION}/courses?lang=${lang}`,
    method: "GET",
  },
  course: {
    url: (courseId: string, lang: Language = "en") =>
      `${BASE_URL}/${API_VERSION}/courses/${courseId}?lang=${lang}`,
    method: "GET",
  },
  chapter: {
    url: (chapterId: string, lang: Language = "en") =>
      `${BASE_URL}/${API_VERSION}/chapters/${chapterId}?lang=${lang}`,
    method: "GET",
  },
  explainer: {
    url: (chapterId: string, lang: Language = "en") =>
      `${BASE_URL}/${API_VERSION}/chapters/${chapterId}/explainer?lang=${lang}`,
    method: "GET",
  },
  challenge: {
    url: (challengeId: string, lang: Language = "en") =>
      `${BASE_URL}/${API_VERSION}/challenges/${challengeId}?lang=${lang}`,
    method: "GET",
  },
  runCode: {
    url: `${BASE_URL}/${API_VERSION}/challenges/run-code`,
    method: "POST",
  },
  requestOtp: {
    url: `${BASE_URL}/${API_VERSION}/user/request-otp`,
    method: "POST",
  },
  verifyOtp: {
    url: `${BASE_URL}/${API_VERSION}/user/verify-otp`,
    method: "POST",
  },
  createUser: {
    url: `${BASE_URL}/${API_VERSION}/user/create`,
    method: "POST",
  },
  getUser: {
    url: `${BASE_URL}/${API_VERSION}/user/me`,
    method: "GET",
  },
  signOut: {
    url: `${BASE_URL}/${API_VERSION}/user/logout`,
    method: "GET",
  },
  googleLogin: {
    url: `${BASE_URL}/${API_VERSION}/user/google-social-login`,
    method: "POST",
  },
  faceBookLogin: {
    url: `${BASE_URL}/${API_VERSION}/user/facebook-social-login`,
    method: "POST",
  },
  needHelpText: {
    url: `${BASE_URL}/${API_VERSION}/openai/need-help-text-stream`,
    method: "POST",
  },
  needHelpAudio: {
    url: `${BASE_URL}/${API_VERSION}/openai/need-help-audio`,
    method: "POST",
  },

  deleteUser: {
    url: `${BASE_URL}/${API_VERSION}/user/delete`,
    method: "DELETE",
  },
  payment: {
    url: `${BASE_URL}/${API_VERSION}/payment`,
    method: "POST",
  },
  localization: {
    url: (lng: Language) => `${BASE_URL}/${API_VERSION}/resources/${lng}`,
  },
} as const;
